import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Container from 'react-bootstrap/Container';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Navbar from 'react-bootstrap/Navbar';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import usaid_logo from './usaid_logo_2.png';
import french_icon from './france-48.png';
import english_icon from './english-48.png';
import dual_purpose_millet from './dual_purpose_millet.jpg'
import { Trans, useTranslation } from 'react-i18next';
import i18next from 'i18next';

function RegionSelect() {

  const [selectedRegion, setRegion] = useState(null);
  const { t } = useTranslation();

  return (
    <DropdownButton id="dropdown-basic-button" title={t('region_select.title')}>
      <Dropdown.Item href="#/select-senegal">{t('region_select.options.senegal')}</Dropdown.Item>
    </DropdownButton>
  );
}

function MainNav() {
  const { t } = useTranslation();
  function selectEnglish() {
    i18next.changeLanguage('en', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      console.log('switched to English');
    })
  }
  function selectFrench() {
    i18next.changeLanguage('fr', (err, t) => {
      if (err) return console.log('something went wrong loading', err);
      console.log('switched to French');
    })
  }
  return (
    <Navbar bg="usaid" variant="light" className='py-0'>
      <Navbar.Brand href="#home">
        <Container className="custom-navbar accent-text">
          <img
            alt="usaid logo"
            src={usaid_logo}
            width="189"
            height="59"
            className="d-inline-block align-center logo"
          />{' '}
          <font size="6">{t('site_title')}</font>
        </Container>
      </Navbar.Brand>
      <DropdownButton title={
        <span>
          <img src={french_icon} style={{ width: 25, height: 25 }} alt="french flag"/>
          <strong> / </strong>
          <img src={english_icon} style={{ width: 25, height: 25 }} alt="english flag"/>
        </span>
      } className="ms-auto language-select" variant='outline-primary'>
        <Dropdown.Item onClick={selectFrench}>
          <img src={french_icon} style={{ width: 25, height: 25 }} alt="french flag"/> {t('language_select.french')}
        </Dropdown.Item>
        <Dropdown.Item onClick={selectEnglish}>
          <img src={english_icon} style={{ width: 25, height: 25 }} alt="egnlish flag"/> {t('language_select.english')}
        </Dropdown.Item>
      </DropdownButton>
    </Navbar >
  )
}

function RedParagraphHeader(props) {
  return <b><font color="#BA0C2F">{props.text}</font></b>
}

function HomeParagraph(props) {
  return (
    <p>
      <RedParagraphHeader text={props.title} />
      <br />
      {props.text}
      {props.children}
    </p>
  )
}

function PageHeader(props) {
  return <h3><font color="#205493">{props.text}</font></h3>
}

export default function App() {
  const { t } = useTranslation();
  return (
    <Container fluid>
      <MainNav />
      <p />
      <Tabs fill justify>
        <Tab eventKey="home" title={t('nav.home')}>
          <p />
          <Container>
            <PageHeader text={t('page_header')} />
            <center><img src={dual_purpose_millet} width="100%" alt="millet field"/></center>
            <p />
            <HomeParagraph text={t('home.paragraph_1.text')} />
            <HomeParagraph title={t('home.paragraph_2.title')} text={t('home.paragraph_2.text')} />
            <HomeParagraph title={t('home.paragraph_3.title')}><Trans i18nKey={'home.paragraph_3.text'}/></HomeParagraph>
            <HomeParagraph title={t('home.paragraph_4.title')} text={t('home.paragraph_4.text')} />
            <HomeParagraph title={t('home.paragraph_5.title')} text={t('home.paragraph_5.text')} />
            <i>{t('home.tool_description')}</i>
            <p />
          </Container>
        </Tab>
        <Tab eventKey="instructions" title={t('nav.instructions')}>
          <p />
          <Container>
            <PageHeader text={t('page_header')} />
            <p />
            {t('instructions.paragraph_1')}
            <p />
            {t('instructions.paragraph_2')}
            <p />
            {/* Note for translator/developer: The <Trans> component is used here because the text contains bold (<strong>) HTML tags. Do not translate the HTML tags, leave them as-is. Use <Trans> whenever the text uses these tags. Otherwise use the t('key_here') function. */}
            <Trans i18nKey={'instructions.paragraph_3'} />
            <p />
            <Trans i18nKey={'instructions.paragraph_4'} />

            <Accordion flush>
              <Accordion.Item eventKey="-1">
                <Accordion.Header>
                  <ul><li>{t('instructions.geo_location.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.geo_location.text')}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <p />
            {t('instructions.paragraph_5')}
            <Accordion flush>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <ul><li>{t('instructions.discount_rate.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  <Trans i18nKey={'instructions.discount_rate.text'} />
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <ul><li>{t('instructions.social_cost.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.social_cost.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>
                  <ul><li>{t('instructions.improved_ag_tech.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.improved_ag_tech.text')}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <p />
            {t('instructions.paragraph_6')}
            <p />
            <Accordion flush>
              <Accordion.Item eventKey="4">
                <Accordion.Header>
                  <ul><li>{t('instructions.till_depth.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.till_depth.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5">
                <Accordion.Header>
                  <ul><li>{t('instructions.plant_density.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.plant_density.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6">
                <Accordion.Header>
                  <ul><li>{t('instructions.fert_use_cowpea.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.fert_use_cowpea.text')}
                  <ul>
                    <li>{t('instructions.fert_use_cowpea.option_1')}</li>
                    <li>{t('instructions.fert_use_cowpea.option_2')}</li>
                    <li>{t('instructions.fert_use_cowpea.option_3')}</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="7">
                <Accordion.Header>
                  <ul><li>{t('instructions.fert_use_millet.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.fert_use_millet.text')}
                  <ul>
                    <li>{t('instructions.fert_use_millet.option_1')}</li>
                    <li>{t('instructions.fert_use_millet.option_2')}</li>
                    <li>{t('instructions.fert_use_millet.option_3')}</li>
                    <li>{t('instructions.fert_use_millet.option_4')}</li>
                    <li>{t('instructions.fert_use_millet.option_5')}</li>
                    <li>{t('instructions.fert_use_millet.option_6')}</li>
                  </ul>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="8">
                <Accordion.Header>
                  <ul><li>{t('instructions.livestock_ownwership.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.livestock_ownwership.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="9">
                <Accordion.Header>
                  <ul><li>{t('instructions.residue_removal.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.residue_removal.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="10">
                <Accordion.Header>
                  <ul><li>{t('instructions.adoption_rate.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.adoption_rate.text')}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <p />
            {t('instructions.paragraph_7')}
            <p />
            <Accordion flush>
              <Accordion.Item eventKey="11">
                <Accordion.Header>
                  <ul><li>{t('instructions.household_labor.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.household_labor.text')}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            {t('instructions.paragraph_8')}
            <p />
            <b>{t('instructions.paragraph_9')}</b>
            <p />
            <Accordion flush>
              <Accordion.Item eventKey="12">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_ha_fin_returns.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_ha_fin_returns.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="13">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_ha_econ_returns.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_ha_econ_returns.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="16">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_ha_fin_returns.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_ha_fin_returns.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="17">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_ha_econ_returns.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_ha_econ_returns.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="20">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_region_fin_returns.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_region_fin_returns.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="21">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_region_econ_returns.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_region_econ_returns.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="24">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_region_fin_returns.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_region_fin_returns.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="25">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_econ_region_returns.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_econ_region_returns.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="28">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_ghg_ha.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_ghg_ha.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="29">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_nitrogen_leaching_ha.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_nitrogen_leaching_ha.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="30">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_ghg_ha.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_ghg_ha.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="31">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_nitrogen_leaching_ha.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_nitrogen_leaching_ha.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="32">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_ghg_region.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_ghg_region.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="33">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_nitrogen_leaching_region.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_nitrogen_leaching_region.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="34">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_ghg_region.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_ghg_region.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="35">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_nitrogen_leaching_region.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_nitrogen_leaching_region.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="36">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_grain_yield_ha.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_grain_yield_ha.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="37">
                <Accordion.Header>
                  <ul><li>{t('instructions.biomass_yield_ha.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.biomass_yield_ha.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="38">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_grain_yield_ha.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_grain_yield_ha.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="39">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_biomass_yield_ha.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_biomass_yield_ha.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="40">
                <Accordion.Header>
                  <ul><li>{t('instructions.tot_grain_yield_region.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.tot_grain_yield_region.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="41">
                <Accordion.Header>
                  <ul><li>{t('instructions.biomass_yield_region.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.biomass_yield_region.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="42">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_grain_yield_region.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_grain_yield_region.text')}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="43">
                <Accordion.Header>
                  <ul><li>{t('instructions.inc_biomass_yield_region.header')}</li></ul>
                </Accordion.Header>
                <Accordion.Body>
                  {t('instructions.inc_biomass_yield_region.text')}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Container>
        </Tab>
        <Tab eventKey="map" title={t('nav.tool')}>
          <iframe title="tableau_tool" src={t('tableau_url')} width="100%" height="1080" />
        </Tab>
      </Tabs>
    </Container>
  );
}
